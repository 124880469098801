export default () => {
    const text =
        [
            "At one time I thought the most important thing was talent. I think now that the young man must possess or teach himself, training himself, in infinite patience, which is to try and to try until it comes right. He must train himself in ruthless intolerance-that is to throw away anything that is false no matter how much he might love that page or that paragraph. The most important thing is insight, that is to be-curiosity-to wonder, to mull, and to muse why it is that man does what he does, and if you have that, then I don't think the talent makes much difference, whether you've got it or not.",
            "In the end, it was the Sunday afternoons he couldn't cope with, and that terrible listlessness that starts to set in about 2:55, when you know you've taken all the baths that you can usefully take that day, that however hard you stare at any given paragraph in the newspaper you will never actually read it, or use the revolutionary new pruning technique it describes, and that as you stare at the clock the hands will move relentlessly on to four o'clock, and you will enter the long dark teatime of the soul.",
            "One thing I'd do was put a great writer's book beside the typewriter and... type out a beautiful and moving paragraph... and see those sentences rising up... and... think, 'Someday maybe I can write like that....' It was like a dream of possibilities for my own self. And maybe I began to know that there was no other way for the sentence... to... arouse the same feeling. The someone writing whose words were rising from the typewriter became like a mentor for me.... You shouldn't do it more than a few times because you must get on with your own work.",
            "If you set your bar at 'amazing' it's awfully difficult to start. Your first paragraph, sketch, formula, sample or concept isn't going to be amazing. Your tenth one might not be either. Confronted with the gap between your vision of perfect and the reality of what you've created, the easiest path is no path. Shrug. Admit defeat. Hit delete. One more reason to follow someone else and wait for instructions. Of course, the only path to amazing runs directly through not-yet-amazing. But not-yet-amazing is a great place to start, because that's where you are.",
            "You can teach almost anyone determined to learn them the basics required to write sentences and paragraphs that say what you want them to say clearly and concisely. It's far more difficult to get people to think like a writer, to give up conventional habits of mind and emotion. You must be able to step inside your character's skin, and at the same time to remain outside the dicey circumstances you have maneuvered her into.",
            "Time passes, as the novelist says. The single most useful trick of fiction for our repair and refreshment: the defeat of time. A century of family saga and a ride up an escalator can take the same number of pages. Fiction sets any conversion rate, then changes it in a syllable. The narrator's mother carries her child up the stairs and the reader follows, for days. But World War I passes in a paragraph. I needed 125 pages to get from Labor Day to Christmas vacation. In six more words, here's spring.",
            "It's Nathaniel Hawthorne Month in English. Poor Nathaniel. Does he know what they've done to him? We're reading The Scarlet Letter one sentence at a time, tearing it up and chewing on its bones. It's all about SYMBOLISM, says Hairwoman. Every word chosen by Nathaniel, every comma, every paragraph break -- these were all done on purpose. To get a decent grade in her class, we have to figure out what he was really trying to say. Why couldn't he just say what he meant? Would they pin scarlet letters on his chest? B for blunt, S for straightforward?",
            "The first sentence of the truth is always the hardest. Each of us had a first sentence, and most of us found the strength to say it out loud to someone who deserved to hear it. What we hoped, and what we found, was that the second sentence of the truth is always easier than the first, and the third sentence is even easier than that. Suddenly you are speaking the truth in paragraphs, in pages. The fear, the nervousness, is still there, but it is joined by a new confidence. All along, you've used the first sentence as a lock. But now you find that it's the key.",
            "I'm not trying to create an aesthetic that's my own; I'm trying to create a way understanding things through drawing and painting. That's the common thread. Things can look different, but that's not what's important. What's important is the process is the same, the ideas are the same, I'm using the same building blocks, but they're different. The larger framework is the same; it's the pieces that change. For me, it's about these different elements, but you're still fitting them together into sentences, words, paragraphs, and stories.",
            "In the learned journal, in the influential newspaper, I discern no form; only some irresponsible shadow; oftener some monied corporation, or some dangler, who hopes, in the mask and robes of his paragraph, to pass for somebody. But through every clause and part of speech of the right book I meet the eyes of the most determined men; his force and terror inundate every word: the commas and dashes are alive; so that the writing is athletic and nimble,--can go far and live long."
        ]

    return (
        text[Math.floor(Math.random() * text.length)]
    )
}